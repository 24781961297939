<script>
import ItemList from "@/components/ItemList/ItemList.vue";

export default {
  name: "InformacionVacante",
  components: {
    ItemList,
  },
  data() {
    return {
      vacante: null,
      selectedCompetencias: [],
      listaCompetencias: [],
    };
  },
  mounted() {
    this.getVacante();
    this.$watch(
      () => this.$route.params,
      () => {
        this.getVacante();
      }
    );
  },
  computed: {
    vacanteId() {
      return this.$route.params.id;
    },
  },
  methods: {
    async getVacante() {
      const response = await this.$store.getters.fetchGet({
        path: `vacante/${this.vacanteId}`,
      });
      this.vacante = await response.json();
      console.log(this.vacante);
    },
  },
};
</script>

<template>
  <div v-if="vacante" class="pb-3">
    <h3 class="sticky">
      <strong>{{ vacante.cargo.nombre }}</strong>
    </h3>
    <div class="grid-container">
      <ItemList
        title="Empresa"
        :value="vacante.cargo.sucursal.empresa.nombre"
      />
      <ItemList title="Nombre de la vacante" :value="vacante.cargo.nombre" />
      <ItemList title="Tipo de contrato" :value="vacante.tipoDeContrato.tipo" />
      <ItemList title="Jornada laboral" :value="vacante.jornadaLaboral.name" />
      <ItemList title="Salario" :value="vacante.salario" />
      <ItemList
        title="Número de vacantes"
        :value="vacante.cantidadDeVacantesInicial"
      />
      <ItemList title="Fecha de publicacion" :value="vacante.fechaDeCreacion" />
      <ItemList title="Fecha de cierre" :value="vacante.fechaDeCierre" />
      <ItemList title="Pais" :value="vacante.pais.descripcion" />
      <ItemList title="Municipio" :value="vacante.municipio.descripcion" />
      <ItemList title="Dirección" :value="vacante.direccion" />
    </div>
    <div v-if="vacante.descripcion">
      <h4 class="mb-2">Descripción</h4>
      <b-list-group>
        <b-list-group-item>
          <p class="text-justify">{{ vacante.descripcion }}</p>
        </b-list-group-item>
      </b-list-group>
    </div>
    <div class="d-flex justify-content-end">
      <router-link
        class="btn btn-primary mt-2"
        :to="{ name: 'FormularioDePostulacion', params: { vacanteId } }"
      >
        Aplicar
      </router-link>
    </div>
  </div>
</template>

<style scoped>
.sticky {
  position: sticky;
  background: #fff;
  .dark & {
    background: #1e2023;
  }
  top: 0;
  z-index: 1;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  padding: 20px;
}
</style>
